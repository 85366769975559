* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

html {
  scroll-behavior: smooth;
  scroll-padding-block-start: 30px;
}

body {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: "PlusJakartaSans", sans-serif;
  color: var(--main-black);
  background-color: var(--body-color);
}

@font-face {
  font-family: "PlusJakartaSans-ExtraLight";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Light";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-SemiBold";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PlusJakartaSans-ExtraBold";
  src: url("../fonts/plus-jakarta-sans/PlusJakartaSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

:root {
  --main-white: #fff;
  --primary-color: #160647;
  --secondary-color: #6b7280;
  --selected-color: #1b698e;
  --main-border: #d1d5db85;
  --light-border: #d1d5db1f;
  --main-black: #000;
  --main-red: #ff0000;
  --bg-color: #114a65;
  --body-color: #f4f5f7;
  --off-white: #ffffff91;
  --dark-pink: #8c116d;
  --orange: #c85803;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.cus-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background-color: #ebebeb;
  border-radius: 100rem;
}

.cus-scrollbar::-webkit-scrollbar-thumb {
  background: #27447c;
  border-radius: 100rem;
}

.cus-scrollbar {
  scrollbar-color: #27447c #ebebeb;
  scrollbar-width: thin;
}

.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}

.user-text-decoration-none {
  text-decoration: none !important;
}

.user-flex-all {
  display: flex;
  justify-content: space-between;
}

/* start input field design css */

.user-form-group .user-form-control input {
  border: 1px solid var(--main-border);
  border-radius: 6px;
  width: 100%;
  padding: 11px 15px;
  background-color: #fcf2fd;
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.user-form-group .user-form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.user-form-group .user-form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.user-form-control fieldset {
  border: 0px;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--main-border) !important;
}


/* end input field design css */

/* start textarea design  */

.user-form-control-textarea:focus-visible {
  outline: none !important;
}

.user-form-group .user-form-control-textarea {
  border: 1px solid var(--main-border);
  border-radius: 6px;
  width: 100%;
  padding: 11px 15px;
  background-color: #fcf2fd;
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  resize: none;
  height: 100px !important;
}

/* end textarea design  */


/* password field */

.user-input-box .user-form-lable {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin: 0px 0px 6px;
  color: var(--primary-color);
}

.user-input-box .MuiInputBase-root {
  width: 100%;
}

.user-input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

.user-form-group .user-form-control-eye {
  border: 1px solid var(--main-border);
}

.user-input-box .user-form-control-eye input {
  border: 0px;
}

.user-input-box .user-form-control-eye input::placeholder {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  opacity: 1;
}

/* End password field */

.user-page-border {
  border: 1px solid var(--main-border);
  border-radius: 6px;
  padding: 20px;
}

/* start switch css */

.user-switch-main {
  margin-left: 20px;
}

.user-switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 400;
}

/* end switch css */

/* start pagination css */

.user-pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--main-border);
  padding-top: 20px;
}

.user-pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.user-pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin: 0 4px;
}

.user-pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--selected-color);
  color: var(--main-white);
}

.user-pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--selected-color);
  color: var(--main-white);
}

/* end pagination css */

/* start progrss bar css */

.user-progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.user-progress-bar-main .user-progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: var(--selected-color);
}

.user-progress-bar-content {
  margin-top: 30px;
}

.user-progress-bar-content .user-page-title {
  font-size: 20px;
  line-height: 30px;
  color: var(--primary-color);
  font-weight: 500;
}

.user-progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */

body .user-error-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 15px;
  font-family: "PlusJakartaSans-Regular";
}

body .footer-error-text {
  min-height: 15px;
}

/* end error text css */

/* / start dropdown css / */

.user-dropdown-box .user-form-control {
  width: 100%;
  position: relative;
}

.user-dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 9.64px 15px;
  border-radius: 4px;
  border: 1px solid var(--main-border);
  background-color: #fcf2fd;
}

.user-grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.user-dropdown-box .MuiMenu-list .user-drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

/* / end dropdown css / */

.user-row {
  grid-gap: 30px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

/* start common modal css */
.user-modal .user-modal-inner {
  padding: 30px 15px;
  width: calc(100% - 30px);
  max-height: calc(100vh - 40px);
  border: 1px solid var(--main-border);
  border-radius: 10px;
}

.user-modal-hgt-scroll {
  max-height: calc(100vh - 194px);
  overflow: auto;
  padding: 0 5px 0px 0px;
  margin-right: -5px;
}

.user-modal-close-icon {
  cursor: pointer;
}

.MuiBackdrop-root {
  background-color: rgb(0 0 0 / 70%);
}

.user-modal-footer {
  padding: 15px 0px 0px;
}

.user-modal-header .modal-close-btn {
  padding: 0px;
  min-width: 30px;
  justify-content: flex-end;
}

/* end common modal css */

/* start common table design  */

.user-table-data-flex {
  display: flex;
  align-items: center;
  width: 100%;
}

.user-table-data-btn-flex {
  display: flex;
}

.user-table-data-btn-flex .user-table-data-btn {
  min-width: 30px;
  padding: 0px;
  justify-content: flex-start;
}

.user-table-data-img {
  margin-right: 15px;
  height: 25px;
  width: 25px;
  object-fit: cover;
  border-radius: 5px;
}

.user-table-data-flex .user-table-data-text {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
}

.page-table-main .table-container {
  padding-bottom: 7px;
  background-color: transparent;
  box-shadow: none;
  overflow: auto;
  max-width: calc(100vw - 0px);
}

.page-table-main .table {
  min-width: 1100px;
}

.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid var(--main-border);
  background-color: #1b698e1f;
}

.page-table-main .table-td {
  font-size: 11px;
  line-height: 15px;
  color: var(--secondary-color);
  font-weight: 400;
  padding: 7px 10px 7px 10px;
  border-bottom: 1px solid var(--main-border);
}

.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-track {
  background: var(--main-border);
  border-radius: 5px;
}

.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* end common table design  */

.user-icon {
  height: 13px;
  width: 13px;
  object-fit: contain;
}

.card-border {
  border-radius: 5px;
  border: 1px solid var(--main-border);
  padding: 20px;
}

.common-card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 10px;
  padding: 15px;
}

.user-body-overflow .MuiTooltip-tooltip {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--selected-color);
  border-radius: 5px;
  color: var(--main-white);
  padding: 4px 8px;
  font-size: 13px;
  line-height: 25px;
  font-weight: 500;
}

.user-body-overflow .MuiTooltip-tooltip .MuiTooltip-arrow::before {
  color: var(--selected-color);
}

.user-userlist-inner-btn-flex {
  display: flex;
  gap: 10px;
}

.user-search-box {
  position: relative;
}

.user-search-box .user-form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.user-search-grey-img {
  position: absolute;
  left: 13px;
  top: 8px;
  margin-right: 10px;
}

.user-search-box .user-form-group .user-form-control input {
  padding: 6.38px 35px;
}

.user-user-list-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-userlist-btn-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* end user css */

/* start user globle css */
.container {
  padding: 0px 15px 0px 15px;
  width: 100%;
  margin: auto;
  max-width: 1280px;
}

.text-decoration-none {
  text-decoration: none !important;
}

.flex-all {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* start input field design css */

.form-group .form-control input {
  border: 1px solid var(--main-border);
  border-radius: 4px;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

.form-group .form-control input::placeholder {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  opacity: 1;
}

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
  border-width: 0px;
}

.form-control fieldset {
  border: 1px solid var(--main-border);
  border-radius: 2px;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

/* end input field design css */

.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-image: linear-gradient(45deg, #04d493, #8552f1);
  border-radius: 12px;
  color: var(--main-white);
  font-size: 20px;
  line-height: 24px;
  font-family: "PlusJakartaSans-Medium";
  padding: 10px 30px;
  text-transform: capitalize;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.primary-btn-main .primary-btn:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.btn-main .btn {
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  padding: 10px 15px;
  text-transform: capitalize;
}

.border-btn-main .border-btn {
  position: relative;
  display: inline-block;
  padding: 10px 21px;
  font-size: 20px;
  line-height: 24px;
  color: var(--main-black);
  font-family: "PlusJakartaSans-Medium";
  text-transform: capitalize;
  background: #fff;
  border-radius: 12px;
  z-index: 1;
}

.border-btn-main .border-btn::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: linear-gradient(45deg, #04d493, #5b7cd2);
  border-radius: inherit;
  z-index: -1;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  padding: 1px;
  /* Adjust according to the border width */
}

.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400;
}

/* start switch css */

.switch-main {
  margin-left: 20px;
}

.switch-lable .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 400;
}

/* end switch css */

/* start pagination css */

.pagination-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 27px;
}

.pagination-main .MuiPagination-ul {
  margin-right: 20px;
}

.pagination-main .MuiPaginationItem-root {
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  padding: 0px;
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin: 0 4px;
}

.pagination-main .MuiPaginationItem-root.Mui-selected:hover {
  background-color: var(--primary-color);
  color: var(--main-white);
}

.pagination-main .MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary-color);
  color: var(--main-white);
}

/* end pagination css */

/* start progrss bar css */

.progress-bar-main {
  max-width: 470px;
  width: 100%;
  position: relative;
}

.progress-bar-main .progress-bar .MuiLinearProgress-bar {
  border-radius: 8px;
  background-color: var(--primary-color);
}

.progress-bar-content {
  margin-top: 30px;
}

.progress-bar-content .user-page-title {
  font-size: 20px;
  line-height: 30px;
  color: #111827;
  font-weight: 400;
}

.progress-bar-text {
  position: absolute;
  right: -35px;
  top: -5px;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-color);
  font-weight: 500;
}

/* end progrss bar css */

/* start error text css */

.error-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}

/* end error text css */

/* / start dropdown css / */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.dropdown-select .MuiSelect-select {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  display: flex;
  padding: 10px 15px;
  border-radius: 4px;
}

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

.dropdown-box .MuiMenu-list .drop-menuitem {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}

/* / end dropdown css / */

/* start common table title */

.table-title-main .table-title-name {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
}

.table-title-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.table-search-flex {
  display: flex;
}

/* end common table title */

/* start add user modal css */
.user-modal .user-add-user-modal-inner-main {
  max-width: 500px;
}

.user-span-text {
  color: #d1d5db;
  margin-left: 3px;
}

.user-modal .user-modal-title {
  color: var(--primary-color);
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}

.user-modal-user-btn-flex {
  display: flex;
  justify-content: flex-end;
  padding-top: 3px;
}

.user-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* end add user modal css */

/* start delete user modal css */

.user-user-circle-img {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin: auto;
  display: flex;
}

.user-modal .user-delete-modal-inner-main {
  max-width: 450px;
}

.user-delete-modal-inner-main .user-delete-modal-title {
  color: var(--primary-color);
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  text-align: center;
  margin: 20px 0px 16px;
}

.user-delete-modal-inner-main .user-delete-modal-para {
  color: var(--secondary-color);
  text-align: center;
  max-width: 304px;
  width: 100%;
  margin: auto;
}

.user-delete-modal-btn-flex {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}

.user-delete-modal-btn-flex .user-modal-cancel-btn {
  margin-right: 15px;
}

.user-delete-modal-btn-flex .user-modal-delete-btn,
.user-delete-modal-btn-flex .user-modal-delete-btn:hover {
  color: #ffffff;
  background-color: #e4004d;
}

.MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgb(17 24 39 / 60%);
}

/* end delete user modal css */

/* start filter css */

.user-filter-main {
  position: relative;
}

.user-filter-main .MuiDrawer-paper {
  width: 100%;
  min-width: 400px;
  max-width: 400px;
}

.user-filter-header {
  background-color: #07415d;
  display: flex;
  justify-content: space-between;
  padding: 16px 25px;
  align-items: center;
}

.user-filter-close-icon {
  height: 21px;
  width: 21px;
  object-fit: contain;
}

.user-filter-header .user-filter-title {
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
}

.user-filter-inner-main {
  padding: 20px 25px;
  background-color: #fff;
}

.user-filter-btn-main {
  display: flex;
  justify-content: flex-end;
}

.user-filter-btn-main .user-filter-cancel-btn {
  margin-right: 15px;
}

.user-filter-footer {
  border-top: 1px solid #d1d5db;
  padding: 15px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0px;
  right: 0px;
}

.user-filter-checkbox-main .user-checkbox-lable {
  margin-right: 20px;
}

/* end filter css */

.user-down-icon,
.user-plus-icon {
  margin-right: 10px;
}

.user-page-title-main .user-page-title {
  color: var(--primary-color);
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  /* position: relative;
      padding-left: 10px; */
}

/* .user-page-title-main .user-page-title::after {
      content: '';
      position: absolute;
      height: 22px;
      width: 4px;
      background-color: var(--selected-color);
      left: 0px;
} */

.user-sub-title-main .user-sub-title {
  color: var(--primary-color);
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
}

.chart-row-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 15px;
}

.pie-chart-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chart-title-main .chart-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
  color: var(--bg-color);
  margin-bottom: 10px;
}

.user-sub-title-main .user-sub-title {
  color: var(--bg-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  position: relative;
}

.user-sub-title-main .user-sub-title::after {
  content: "";
  position: absolute;
  height: 2px;
  width: 35px;
  background-color: var(--bg-color);
  left: 0px;
  bottom: -2px;
}

.title-row-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0px;
}

.page-table-main .table-not-found-td {
  padding: 0px;
  border: 0px;
}

.data-not-found-main {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.03);
  background-color: var(--main-white);
  border: 1px solid var(--main-border);
  border-radius: 5px;
  padding: 9px;
  width: 100%;
  margin: 10px 0px 0px;
}

.data-not-found-main .data-not-found-text {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  text-align: center;
}

/* start loader css */
.loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 255px);
}

.loader {
  width: 50px;
  height: 50px;
  display: inline-block;
  padding: 0px;
  text-align: left;
}

.loader span {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: var(--bg-color);
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
}

.loader span:last-child {
  animation-delay: -0.9s;
  -webkit-animation-delay: -0.9s;
}

@keyframes loader {
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0.8;
  }

  100% {
    -webkit-transform: scale(1, 1);
    opacity: 0;
  }
}

/* end loader css */

/* start select 2 css */
.user-select2-main {
  width: 300px;
}

.user-select2-main .select2-container--default .select2-selection--multiple {
  background-color: transparent;
  border: 1px solid var(--main-border);
}

.user-select2-main .select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid var(--main-border);
  background-color: transparent;
}

.user-select2-main .select2-container .select2-search--inline .select2-search__field {
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.user-select2-main .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 5px 0px;
}

.user-select2-main .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: transparent;
  border: 1px solid var(--main-border);
  color: var(--secondary-color);
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

/* end select 2 css */

/* start accordian design  */

.accordian-main .accordian-summary {
  background-color: var(--light-gray);
  min-height: 30px;
}

.accordian-main .accordian {
  margin-bottom: 10px;
  box-shadow: none;
}

.accordian-main .accordian-details {
  font-size: 12px;
  line-height: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  border: 1px solid var(--main-border);
}

.accordian-main .MuiAccordion-root::before {
  background-color: transparent;
}

.accordian-main .MuiAccordionSummary-content.Mui-expanded {
  margin: 5px 0px;
}

.accordian-main .MuiAccordionSummary-content {
  font-size: 12px;
  line-height: 18px;
  color: var(--primary-color);
  font-weight: 500;
  margin: 5px 0px;
}

.accordian-main .accordian:last-child {
  margin-bottom: 0px;
}

.accordian-main .accordian.Mui-expanded {
  margin: 10px 0;
}

.accordian-main .accordian-summary.Mui-expanded {
  min-height: 30px;
  padding: 5px 16px;
}

/* end accordian design */

img[alt] {
  font-size: 10px;
}

/* start header css */

.header-main {
  background-color: rgb(255 255 255 / 47%);
  padding: 15px 0px;
  border-bottom: 1px solid var(--main-white);
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-logo-box .header-logo {
  height: 60px;
  max-width: 60px;
  object-fit: contain;
  width: 100%;
}

.header-nav-main .header-nav-ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  gap: 50px;
}

.header-nav-main .header-nav-li {
  padding: 0px;
  max-width: max-content;
}

.header-nav-main .header-nav-li:last-child {
  margin-right: 0px;
}

.header-nav-main .header-nav-link {
  color: var(--main-black);
  font-size: 18px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Medium";
  text-decoration: none;
}

.header-nav-main .header-nav-link::after,
.header-nav-main .header-nav-link::before {
  content: "";
  height: 2px;
  width: 0;
  opacity: 0;
  background-color: #5b7cd2;
  position: absolute;
  transition: all 0.5s;
}

.header-nav-main .header-nav-link:before {
  top: -6px;
}

.header-nav-main .header-nav-link:after {
  bottom: -6px;
  right: 0;
}

.header-nav-main .header-nav-link:hover:before,
.header-nav-main .header-nav-link:hover:after {
  width: calc(100% + 0px);
  opacity: 0.7;
}

.header-nav-main .header-nav-link:hover {
  color: #5b7cd2;
}

.header-right-main {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header-user-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
  margin-right: 6px;
}

.header-right-main .header-login-link {
  color: #1f1f1f;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.header-search-icon {
  width: 15.488px;
  height: 15.488px;
  object-fit: contain;
}

.header-right-main .mobile-menu-btn {
  display: none;
}

/* end header css */

.hero-section {
  background-image: url(../images/png/hero-bg.png);
  height: 729px;
  width: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  padding: 122px 0px 138px;
}

.hero-section::after {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  background-image: url(../images/svg/hero-shapes.svg);
  background-repeat: no-repeat;
  background-size: contain;
  left: 5%;
  bottom: 21%;
  animation: rotate 5000ms infinite linear;
  -webkit-animation: rotate 5000ms infinite linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

.hero-coin-img {
  width: 100%;
  max-width: 468px;
  height: 468px;
  object-fit: cover;
  animation: bounce 0.4s ease infinite alternate;
}

@keyframes bounce {
  0% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }

  100% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
  }
}

.hero-content-main .hero-para {
  font-size: 20px;
  line-height: 26px;
  font-family: "PlusJakartaSans-Medium";
  color: var(--main-black);
  text-transform: uppercase;
  margin-bottom: 19px;
  border-bottom: 1px dashed var(--main-black);
  max-width: max-content;
  border-spacing: 15px;
}

.hero-content-main .hero-title {
  font-size: 87px;
  line-height: 70px;
  font-family: "PlusJakartaSans-Bold";
  color: var(--primary-color);
}

.hero-title-small {
  font-size: 60px;
  line-height: 70px;
  color: var(--primary-color);
  font-family: "PlusJakartaSans-Bold";
}

.hero-highlight-title {
  font-size: 60px;
  line-height: 70px;
  font-family: "PlusJakartaSans-Bold";
  padding-left: 10px;
  background: -webkit-linear-gradient(145deg,
      #160647,
      #10de62 0%,
      #7d50ff 50%,
      #ff6c02 100%);
  background: linear-gradient(145deg,
      #160647,
      #10de62 0%,
      #7d50ff 50%,
      #ff6c02 100%);
  -webkit-background-clip: text;
  background-clip: text;
  background-size: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.hero-content-main .hero-sub-title {
  color: var(--primary-color);
  font-size: 25px;
  line-height: 35px;
  font-family: "PlusJakartaSans-Bold";
  margin-top: 10px;
}

.hero-btn-main {
  margin: 48px 0px 24px;
}

.hero-coin-img-main {
  display: flex;
  justify-content: center;
}

.hero-btn-main .hero-btn,
.hero-btn-main .hero-btn:hover {
  background-color: var(--orange);
  border-radius: 50px;
  font-size: 15px;
  line-height: 24px;
  color: var(--main-white);
  font-family: "PlusJakartaSans-Semibold";
  padding: 10.5px 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.hero-btn-main .hero-btn:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.hero-copy-main {
  box-shadow: 0px 8px 16px 0px #00000014, 0px 0px 4px 0px #0000000a;
  background-color: var(--main-white);
  padding: 8px 10px 8px 15px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 447px;
}

.hero-copy-main .hero-copy-text {
  font-size: 20px;
  line-height: 26px;
  font-family: "PlusJakartaSans-Regular";
  color: var(--main-black);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.hero-copy-btn-main .hero-copy-btn,
.hero-copy-btn-main .hero-copy-btn:hover {
  height: 33px;
  min-width: 33px;
  width: 100%;
  padding: 0;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-black);
}

.hero-copy-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.why-choose-main {
  background: linear-gradient(111deg, #17044b 0%, #3c0556 100%);
}

.why-choose-section {
  /* background: linear-gradient(111deg, #17044B 0%, #3C0556 100%), url(../images/png/why-choose-bg.png); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 681px;
  width: 100%;
  padding: 120px 0px 114px;
  background-image: url(../images/png/why-choose-bg.png);
}

.section-title-main .section-title {
  color: var(--primary-color);
  font-size: 50px;
  line-height: 63px;
  font-family: "PlusJakartaSans-Bold";
  text-align: center;
  padding-bottom: 23px;
  position: relative;
}

.section-title-main .section-title.section-title-white {
  color: var(--main-white);
}

.section-title-main .section-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: 3px;
  width: 126px;
  background-color: #dfaff5;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.section-title-main .section-title-para {
  color: #c5b5d6;
  font-size: 18px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Medium";
  text-align: center;
  margin: 27px 0px 0px;
}

.section-highlight-text {
  background: linear-gradient(175deg, #10de62 13%, #ff6c02 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.choose-row {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
}

.choose-round-main {
  border: 3px solid rgb(255 255 255 / 20%);
  border-radius: 50%;
  height: 98px;
  max-width: 98px;
  min-width: 98px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto 31px auto;
  transition: 0.5s ease-in-out;
  animation: rotateGradiant 5000ms infinite linear;
  -webkit-animation: rotateGradiant 5000ms infinite linear;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

@keyframes rotateGradiant {
  25% {
    border: 2px dotted #ff6c02;
  }

  50% {
    border: 2px dashed #10de62;
  }

  75% {
    border: 2px dotted #8552f1;
  }

  100% {
    border: 2px dashed #ff7876;
  }
}

.choose-round-fill {
  background-image: linear-gradient(45deg, #10de62, #ff6c02);
  border-radius: 50%;
  height: 68px;
  max-width: 68px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotate 5000ms infinite linear;
  -webkit-animation: rotate 5000ms infinite linear;
}

.choose-logo {
  height: 40px;
  width: 31px;
  object-fit: contain;
}

.choose-col .choose-title {
  font-size: 24px;
  line-height: 32px;
  font-family: "PlusJakartaSans-SemiBold";
  color: var(--main-white);
  padding-bottom: 24px;
  position: relative;
  text-align: center;
}

.choose-col .choose-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: 1px;
  width: 137px;
  background-color: #dfaff5;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  -o-border-radius: 1px;
}

.choose-col .choose-para {
  font-size: 16px;
  line-height: 23px;
  font-family: "PlusJakartaSans-Regular";
  color: #c5b5d6;
  text-align: center;
  margin-top: 20px;
}

.token-section {
  padding: 87px 0px 110px;
  position: relative;
  overflow: hidden;
}

.token-section::before {
  content: "";
  position: absolute;
  left: 0%;
  top: 0%;
  background-image: url(../images/svg/token-shape-top.svg);
  height: 402px;
  width: 494px;
  background-repeat: no-repeat;
  background-size: contain;
}

.token-section::after {
  content: "";
  position: absolute;
  right: -3%;
  bottom: -4%;
  background-image: url(../images/svg/token-shape-bottom.svg);
  height: 402px;
  width: 494px;
  background-repeat: no-repeat;
  background-size: contain;
}

.section-title-main .section-backword-sub-title {
  font-size: 20px;
  line-height: 27px;
  font-family: "PlusJakartaSans-Medium";
  color: var(--main-black);
  text-align: center;
  text-transform: uppercase;
  padding: 0px 0px 2px;
  position: relative;
  max-width: max-content;
  margin: auto;
  margin-bottom: 18px;
}

.section-title-main .section-backword-sub-title::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  border: 1px dashed var(--main-black);
  width: 100%;
}

.section-title-token::after {
  display: none;
}

.tokenomics-content-box {
  box-shadow: 0px 5px 30px 0px #0000001a;
  background-color: rgb(255 255 255 / 10%);
  border-radius: 20px;
  padding: 17px 22px 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.tokenomics-content-box:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.tokenomics-content-main .tokenomics-content-title {
  font-size: 20px;
  line-height: 26px;
  font-family: "PlusJakartaSans-SemiBold";
  color: var(--main-black);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  /* margin-bottom: 10px; */
}

.token-row {
  margin-top: 70px;
  align-items: center;
}

.tokenomics-content-main .tokenomics-content-para {
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Light";
  color: #101111;
}

.tokenomics-content-main .tokenomics-content-lable {
  font-size: 20px;
  line-height: 32px;
  font-family: "PlusJakartaSans-SemiBold";
  color: var(--main-black);
  text-align: right;
  margin-bottom: 10px;
}

.tokenomics-content-main .tokenomics-content-digit {
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Regular";
  text-align: right;
  color: #101111;
}

.tokenomics-content-box::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.tokenomics-content-box:last-child {
  margin-bottom: 0;
}

.tokenomics-content-box:nth-child(1):after {
  border-left: 2px solid #ff7876;
}

.tokenomics-content-box:nth-child(2):after {
  border-left: 2px solid #36ffad;
}

.tokenomics-content-box:nth-child(3):after {
  border-left: 2px solid #ff3169;
}

.tokenomics-content-box:nth-child(4):after {
  border-left: 2px solid #9f49a3;
}

.tokenomics-content-box:nth-child(5):after {
  border-left: 2px solid #ffa523;
}

.tokenomics-content-box:nth-child(6):after {
  border-left: 2px solid #0068b9;
}

.token-chart-value-box .token-chart-value-lable {
  font-size: 21px;
  line-height: 28px;
  font-family: "PlusJakartaSans-Regular";
  color: var(--main-black);
  margin-bottom: 15px;
  text-align: center;
}

.token-chart-value-box .token-chart-value-text {
  font-size: 20px;
  line-height: 31px;
  font-family: "PlusJakartaSans-Bold";
  color: var(--main-black);
  text-align: center;
}

.token-chart-value-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.token-chart-main {
  position: relative;
}

.section-title-main .section-backword-title {
  color: rgb(109 116 124 / 10%);
  font-size: 96px;
  line-height: 120px;
  text-align: center;
  font-family: "PlusJakartaSans-Bold";
  margin-bottom: -25px;
}

.section-title-main .section-title.section-title-team::after {
  background-color: var(--main-black);
}

.section-title-main .section-title-para.section-title-para-black {
  color: var(--main-black);
  max-width: 680px;
  margin: 26px auto 0;
  width: 100%;
}

.team-section {
  padding: 90px 0px;
}

.team-row {
  margin-top: 60px;
}

body .error-text {
  color: var(--main-red);
  font-size: 12px;
  line-height: 15px;
  font-family: 'PlusJakartaSans-Regular';
}

.team-card {
  /*box-shadow: 0px 8px 16px 0px #00000014, 0px 0px 4px 0px #0000000A;
      */
  box-shadow: 0px 8px 16px 0px #00000014, 0px 0px 3px 0px #0000000a;
  background-color: var(--main-white);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 28px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.team-card:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  border: 1px solid #3859f2;
}

.team-card:last-child {
  margin-bottom: 0;
}

.team-img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  margin-bottom: 28px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  max-height: 246px;
  min-height: 246px;
  object-fit: cover;
}

.team-content-main .team-title {
  font-size: 24px;
  line-height: 30px;
  font-family: "PlusJakartaSans-SemiBold";
  color: var(--main-black);
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 30px;
}

.team-content-main .team-sub-title {
  font-size: 18px;
  line-height: 23px;
  font-family: "PlusJakartaSans-Medium";
  color: #6d747c;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 23px;
}

.faq-section {
  /* padding: 235px 0px 191px; */
  padding: 90px 0px 191px;
  background-image: url(../images/svg/team-bg.svg);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* margin-top: -235px; */
}

.section-wrapper {
  background-color: var(--main-white);
}

.section-title-faq::after {
  display: none;
}

.accrodion-main {
  margin-top: 90px;
}

.accrodion-main .accrodion {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  background-color: var(--main-white);
  box-shadow: none;
  margin-bottom: 20px;
}

.accrodion-main .accrodion:last-child {
  margin-bottom: 0;
}

.accrodion-main .accrodion-summary {
  font-size: 22px;
  line-height: 30px;
  font-family: "PlusJakartaSans-Bold";
  color: var(--main-black);
  padding: 22px 30px;
}

.accrodion-main .accrodion-details {
  color: #6d747c;
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Regular";
  padding: 0px 30px 30px;
  word-wrap: break-word; /* Corrects long text wrap */
  overflow-wrap: break-word; /* Ensures text wraps instead of breaking */
}

.accrodion-main .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0;
}

.accrodion-main .MuiAccordion-root::before {
  display: none;
}

.accrodion-main .MuiAccordionSummary-content {
  margin: 0;
}

.accrodion-main svg {
  display: none;
}

.accrodion-main .MuiAccordionSummary-expandIconWrapper {
  background-image: url(../images/svg/down-arrow.svg);
  height: 22px;
  width: 18px;
  background-repeat: no-repeat;
  background-size: contain;
}

.footer {
  background-image: url(../images/svg/footer-bg.svg);
  height: 577px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 69px 0px 0px;
}

.footer-bg-main {
  background-image: linear-gradient(45deg, #17044b, #3c0556);
}

.footer-content-main .footer-title {
  font-size: 36px;
  line-height: 45px;
  font-family: "PlusJakartaSans-Bold";
  color: var(--main-white);
  text-align: center;
  margin-bottom: 56px;
}

.footer-input-box input {
  background-color: #382463;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  padding: 16.5px 20px;
  color: #c5b5d6;
  font-size: 16px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Regular";
}

.footer-content-main .footer-para {
  color: var(--main-white);
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Regular";
  max-width: 636px;
  width: 100%;
  margin: 25px auto 33px;
  text-align: center;
}

.footer-social-main .footer-social-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style-type: none;
}

.footer-social-ul .footer-social-li {
  height: 40px;
  width: 40px;
  background-color: #382463;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  transition: 0.5s ease-in-out;
}

.footer-social-ul .footer-social-li:hover {
  background-image: linear-gradient(45deg, #04d493, #8552f1);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.footer-social-icon {
  height: 17px;
  width: 17px;
  object-fit: contain;
}

.footer-input-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.footer-input-box .footer-input {
  width: 100%;
  min-width: 417px;
  max-width: 471px;
}

.footer-copy-right-box {
  border-top: 1px solid #414073;
  padding: 22px 0px;
}

.footer-copy-right-box .footer-copy-right-text {
  color: #e0e0e0;
  font-size: 16px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Regular";
  text-align: center;
  margin: 0px;
}

.primary-btn-main .footer-btn,
.primary-btn-main .footer-btn:hover {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 18px 17px;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
}

.primary-btn-main .footer-btn:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.roadmap-section {
  background: linear-gradient(137.48deg,
      #ffffff -47.56%,
      #f1eaf8 -24.98%,
      #f2ffff 7.47%,
      #edeeff 29.18%,
      #f1e4eb 53.96%,
      #efe7fc 74.57%,
      #e0e0e0 92.77%,
      #f2f7ff 131.8%,
      #fcfcff 149.12%,
      #ffffff 171.71%);
  height: 700px;
  width: 100%;
  padding: 105px 0px 115px;
}

.section-title-main .section-title.section-title-roadmap::after {
  background-color: var(--primary-color);
}

.roadmap-row {
  margin-top: 60px;
}

.roadmap-card {
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  border-radius: 20px;
  padding: 18px 32px;
}

.roadmap-card .roadmap-title {
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Bold";
  color: var(--main-black);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 22px;
  margin-bottom: 12px;
}

.roadmap-card p {
  font-size: 14px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Medium";
  color: #6d747c;
  /* text-align: center; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical; */
  /* min-height: 40px; */
}


.roadmap-card ul li {
  font-size: 14px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Medium";
  color: #6d747c;
  word-break: break-word;
}


.roadmap-row .roadmap-owl .owl-nav .owl-prev,
.roadmap-row .roadmap-owl .owl-nav .owl-prev:hover,
.roadmap-row .roadmap-owl .owl-nav .owl-next,
.roadmap-row .roadmap-owl .owl-nav .owl-next:hover {
  background-color: transparent;
  position: absolute;
  margin: 0;
  padding: 0;
}

.owl-next img,
.owl-prev img {
  width: 41px;
  height: 41px;
  object-fit: contain;
}

.roadmap-row .roadmap-owl .owl-nav .owl-prev {
  left: -9%;
  top: -60%;
}

.roadmap-row .roadmap-owl .owl-nav .owl-next {
  right: -9%;
  top: -60%;
}

.stepper-wrapper {
  width: 100%;
  z-index: 1;
  position: relative;
  margin: 60px 0px 0px;
}

.stepper-wrapper .stepper-ul {
  padding: 1px;
}

.stepper-ul .stepper-li {
  list-style-type: none;
  float: left;
  width: 20%;
  padding: 0;
}

.stepper-ul .stepper-li:before {
  content: " ";
  line-height: 30px;
  border-radius: 6px;
  width: 22px;
  height: 22px;
  display: block;
  text-align: center;
  margin: 0 auto 0px;
  background: #fff;
  border: 3px solid #91a4ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.stepper-ul .stepper-li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: rgb(145 164 255 / 50%);
  top: 12px;
  left: -50%;
  z-index: -1;
}

.stepper-ul .stepper-li:first-child:after {
  content: none;
}

.stepper-ul .stepper-li.active:before {
  border-color: #3859f2;
  background: linear-gradient(180deg, #5270fc 0%, #3859f2 100%);
}

.stepper-ul .active:after {
  background: linear-gradient(180deg, #5270fc 0%, #3859f2 100%);
}

.mobile-navbar-show {
  display: none;
}

.mobile-navbar-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 5px 0px 15px;
  border-bottom: 1px solid var(--main-border);
}

.header-nav-logo {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.mobile-navbar-head .mobile-navbar-title {
  font-size: 20px;
  line-height: 24px;
  font-family: "PlusJakartaSans-Medium";
  color: var(--primary-color);
}

.mobile-navbar-head .mobile-navbar-close-btn {
  min-width: 30px;
  padding: 0;
  justify-content: flex-end;
}

.mobile-navbar-close {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.mobile-navbar-logo-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.footer-social-link {
  display: flex;
}

.footer-nav-main {
  margin: 52px 0px 29px;
}

.footer-nav-main .footer-nav-list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 60px;
}

.footer-nav-list .footer-nav-li {
  max-width: max-content;
  padding: 0;
  margin: 0;
}

.footer-nav-list .footer-nav-link {
  color: #e0e0e0;
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Medium";
  text-decoration: none;
}

.inner-page-banner-main {
  padding: 70px 0px;
  position: relative;
}

.blog-details-bg {
  background-image: url(../images/png/blog-bg.png);
  width: 100%;
  height: 264px;
  background-repeat: no-repeat;
  background-size: cover;
}

.blog-details-bg::after {
  content: "";
  position: absolute;
  height: 90px;
  width: 80px;
  background-image: url(../images/svg/hero-shapes.svg);
  background-repeat: no-repeat;
  background-size: contain;
  left: 17%;
  bottom: 12%;
  animation: rotate 5000ms infinite linear;
  -webkit-animation: rotate 5000ms infinite linear;
}

.blog-details-bg::before {
  content: "";
  position: absolute;
  height: 90px;
  width: 80px;
  background-image: url(../images/svg/hero-shapes.svg);
  background-repeat: no-repeat;
  background-size: contain;
  right: 17%;
  top: 12%;
  animation: rotate 5000ms infinite linear;
  -webkit-animation: rotate 5000ms infinite linear;
}

.inner-page-banner-content {
  margin: auto;
}

.inner-page-banner-content .inner-page-heading {
  font-size: 40px;
  line-height: 60px;
  font-family: "PlusJakartaSans-Medium";
  color: var(--primary-color);
  margin-bottom: 15px;
  text-align: center;
}

.inner-page-breadcrumb {
  border: 1px solid #4e3d73;
  padding: 11px 33px;
  border-radius: 55px;
  -webkit-border-radius: 55px;
  -moz-border-radius: 55px;
  -ms-border-radius: 55px;
  -o-border-radius: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: max-content;
}

.inner-page-banner-content .inner-page-breadcrumb-link {
  font-size: 16px;
  line-height: 28px;
  font-family: "PlusJakartaSans-Medium";
  color: var(--main-black);
}

.inner-page-banner-content .inner-page-breadcrumb-link-gap {
  padding: 0px 5px;
}

.inner-page-main {
  padding: 87px 0px 75px;
  background-color: #ffffff;
}

.blog-flex {
  display: flex;
  gap: 29px;
  flex-wrap: wrap;
}

.blog-left-main {
  max-width: 811px;
  width: 100%;
  flex: 0 0 811px;
}

.blog-right-main {
  flex: 1;
}

.blog-card {
  display: flex;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  margin-bottom: 30px;
}

.blog-card:last-child {
  margin-bottom: 0;
}

.blog-card-img {
  position: relative;
  display: flex;
}

.blog-img {
  height: 269px;
  width: 100%;
  max-width: 324px;
  min-width: 324px;
  object-fit: cover;
  border-radius: 15px 0px 0px 15px;
  -webkit-border-radius: 15px 0px 0px 15px;
  -moz-border-radius: 15px 0px 0px 15px;
  -ms-border-radius: 15px 0px 0px 15px;
  -o-border-radius: 15px 0px 0px 15px;
}

.blog-card-inner-right {
  background-color: #ffffff;
  padding: 15px 47px 15px 27px;
  border: 1px solid #e9ecef;
  border-left: 0;
  border-radius: 0px 15px 15px 0px;
  -webkit-border-radius: 0px 15px 15px 0px;
  -moz-border-radius: 0px 15px 15px 0px;
  -ms-border-radius: 0px 15px 15px 0px;
  -o-border-radius: 0px 15px 15px 0px;
}

.blog-card-inner-right .blog-card-heading {
  font-size: 20px;
  line-height: 28px;
  font-family: "PlusJakartaSans-SemiBold";
  color: var(--main-black);
  margin: 0px 0px 10px;
}

.blog-card-inner-right .blog-card-para {
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Regular";
  color: #777777;
  margin: 0px 0px 24px;
}

.blog-profile-flex {
  display: flex;
  gap: 9px;
  align-items: center;
}

.blog-profile-img {
  height: 50px;
  width: 50px;
  object-fit: contain;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.blog-profile-content-main .blog-profile-name {
  font-size: 16px;
  line-height: 20px;
  font-family: "PlusJakartaSans-SemiBold";
  color: #222222;
  margin-bottom: 0px;
}

.blog-profile-content-main .blog-profile-date {
  font-size: 14px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Medium";
  color: #494949;
}

.blog-card-btn-main .blog-card-btn,
.blog-card-btn-main .blog-card-btn:hover {
  background-color: #9c0fdb;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 5px 16px;
  text-align: center;
  color: var(--main-white);
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "PlusJakartaSans-Regular";
}

.blog-card-btn-main {
  position: absolute;
  bottom: 8%;
  left: 8%;
}

.pink-right-arrow {
  height: 11px;
  width: 16px;
  margin-right: 7px;
  object-fit: contain;
}

.blog-category-list-main .blog-category-list {
  padding: 0;
}

.blog-category-list-main .blog-category-list-item {
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 0px;
}

.blog-category-list-main .blog-category-list-item:last-child {
  border: 0;
}

.blog-category-list-main .blog-category-list-text {
  color: #808080;
  font-size: 15px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Regular";
}

.blog-inner-title-main .blog-inner-title {
  font-size: 20px;
  line-height: 26px;
  font-family: "PlusJakartaSans-SemiBold";
  color: #222222;
  padding: 0px 0px 8px;
  position: relative;
  margin-bottom: 5px;
  max-width: max-content;
}

.blog-inner-title-main .blog-inner-title::after {
  content: "";
  height: 2px;
  width: 50%;
  position: absolute;
  left: 40%;
  bottom: 0;
  background-color: #9c0fdb;
  margin-top: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.blog-inner-title-main .blog-inner-title::before {
  content: "";
  height: 2px;
  width: 30%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #9c0fdb;
  margin-top: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.gray-card {
  background-color: #e9ecef;
  border-radius: 10px;
  padding: 20px 37px 5px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 30px;
}

.gray-card:last-child {
  margin-bottom: 0;
}

.blog-recent-list-main {
  margin-top: 20px;
}

.blog-recent-list-box {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  text-decoration: none;
}

.blog-recent-list-img {
  height: 80px;
  width: 97px;
  object-fit: contain;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.blog-recet-content-main .blog-recent-list-text {
  color: #222222;
  font-size: 16px;
  line-height: 20px;
  font-family: "PlusJakartaSans-SemiBold";
  margin-bottom: 10px;
}

.blog-resent-date-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-resent-date-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

.blog-resent-date-flex .blog-recent-date-text {
  color: #585858;
  font-size: 16px;
  line-height: 20px;
  font-family: "PlusJakartaSans-Regular";
}

.blog-deatils-img {
  width: 100%;
  height: 344px;
  object-fit: cover;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.blog-deatils-card-img {
  margin-bottom: 22px;
}

.blog-deatils-card .blog-deatils-card-heading {
  font-size: 40px;
  line-height: 49px;
  font-family: "PlusJakartaSans-SemiBold";
  color: #222222;
  margin-bottom: 16px;
  margin-top: 10px;
}

.blog-deatils-card .blog-deatils-card-para {
  font-size: 16px;
  line-height: 27px;
  font-family: "PlusJakartaSans-Regular";
  color: #777777;
}

.blog-share-main {
  border-top: 1px dashed #cccccc;
  border-bottom: 1px dashed #cccccc;
  padding: 21px 0px;
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 43px;
}

.blog-share-list-main .blog-share-list {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blog-share-main .blog-share-text {
  font-size: 16px;
  line-height: 20px;
  font-family: "PlusJakartaSans-SemiBold";
  color: #222222;
}

.blog-share-list-main .blog-share-list-item {
  max-width: max-content;
  padding: 0;
}

.blog-share-list-main .blog-share-list-link {
  width: 37px;
  height: 35px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9c0fdb;
}

.blog-share-list-icon {
  height: 17px;
  width: 17px;
  object-fit: contain;
}

.blog-artical-section {
  margin: 65px 0px 0px;
}

.inner-section-title-main .inner-section-title {
  color: var(--primary-color);
  font-size: 40px;
  line-height: 50px;
  font-family: "PlusJakartaSans-Bold";
}

.inner-section-title-main {
  margin-bottom: 39px;
}

.blog-artical-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 34px;
}

.blog-artical-card-img {
  position: relative;
  display: flex;
}

.blog-artical-img {
  width: 100%;
  height: 255px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
  -webkit-border-radius: 15px 15px 0px 0px;
  -moz-border-radius: 15px 15px 0px 0px;
  -ms-border-radius: 15px 15px 0px 0px;
  -o-border-radius: 15px 15px 0px 0px;
}

.blog-artical-col .blog-artical-card-heading {
  font-size: 20px;
  line-height: 27px;
  font-family: "PlusJakartaSans-SemiBold";
  color: #222222;
  margin-bottom: 17px;
}

.blog-artical-col .blog-artical-card-para {
  font-size: 16px;
  line-height: 27px;
  font-family: "PlusJakartaSans-Regular";
  color: #777777;
  margin-bottom: 19px;
}

.blog-artical-conetnt-main {
  padding: 30px 28px;
  border: 1px solid #e9ecef;
  border-top: 0;
  border-radius: 0px 0px 10px 10px;
  -webkit-border-radius: 0px 0px 10px 10px;
  -moz-border-radius: 0px 0px 10px 10px;
  -ms-border-radius: 0px 0px 10px 10px;
  -o-border-radius: 0px 0px 10px 10px;
}

.blog-artical-btn-main .blog-artical-btn,
.blog-artical-btn-main .blog-artical-btn:hover {
  background-color: #9c0fdb;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 5px 16px;
  text-align: center;
  color: var(--main-white);
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "PlusJakartaSans-Regular";
}

.blog-artical-btn-main {
  position: absolute;
  bottom: 8%;
  left: 6%;
}

.editer-content p {
  color: var(--main-black);
  font-size: 13px;
  line-height: 29px;
  font-weight: 400;
  margin-bottom: 20px;
}

.editer-content strong {
  color: var(--main-black);
  font-size: 15px;
  line-height: 29px;
  font-weight: 500;
}

.editer-content ul {
  padding: 0 0 0 15px;
  margin: 0px;
}

.editer-content li {
  color: var(--main-black);
  font-size: 13px;
  line-height: 29px;
  font-weight: 400;
  margin-bottom: 20px;
}

.editer-content ol {
  padding: 0 0 0 15px;
  margin: 0px;
}

.editer-content b {
  color: var(--main-black);
  font-size: 13px;
  line-height: 29px;
  font-weight: 500;
}

.editer-content i {
  font-size: 13px;
  line-height: 29px;
  font-weight: 600;
  color: var(--main-black);
}

.editer-content h1 {
  color: var(--main-black);
  font-size: 13px;
  line-height: 29px;
  font-weight: 600;
}

.editer-content h2,
h3,
h4,
h5,
h6 {
  color: var(--main-black);
  font-size: 13px;
  line-height: 29px;
  font-weight: 500;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  overflow: hidden;
}

.marquee-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  transform: translateX(0);
  white-space: nowrap;
  animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s linear infinite;
}

.headline-card .headline-img {
  height: 25px;
  width: 25px !important;
  object-fit: contain;
}

.headline-card {
  display: flex;
  gap: 16px;
  align-items: center;
}

.headline-card .headline-title {
  font-size: 16px;
  line-height: 22px;
  font-family: "PlusJakartaSans-Bold";
  color: var(--main-white);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.headline-section {
  background-color: var(--primary-color);
  padding: 15px;
  display: flex;
}

.inner-page-main .inner-page-para {
  color: var(--secondary-color);
  font-size: 15px;
  line-height: 30px;
  font-family: "PlusJakartaSans-Regular";
  margin-bottom: 10px;
}

.editer-content-main p {
  color: var(--secondary-color);
  font-size: 13px;
  line-height: 29px;
  font-family: "PlusJakartaSans-Regular";
  margin-bottom: 20px;
}

.editer-content-main strong {
  color: var(--secondary-color);
  font-size: 15px;
  line-height: 29px;
  font-family: "PlusJakartaSans-Medium";
  margin-bottom: 10px;
}

.editer-content-main ul {
  padding: 0 0 0 15px;
  margin: 0px;
}

.editer-content-main li {
  color: var(--secondary-color);
  font-size: 13px;
  line-height: 29px;
  font-family: "PlusJakartaSans-Regular";
  margin-bottom: 20px;
}

.editer-content-main ol {
  padding: 0 0 0 15px;
  margin: 0px;
}

.editer-content-main b {
  color: var(--secondary-color);
  font-size: 13px;
  line-height: 29px;
  font-family: "PlusJakartaSans-Medium";
}

.editer-content-main i {
  font-size: 13px;
  line-height: 29px;
  font-family: "PlusJakartaSans-Semibold";
  color: var(--secondary-color);
}

.editer-content-main h1,
.editer-content-main h2,
.editer-content-main h3,
.editer-content-main h4,
.editer-content-main h5,
.editer-content-main h6 {
  color: var(--main-black);
  font-size: 13px;
  line-height: 29px;
  font-family: "PlusJakartaSans-Medium";
  margin-bottom: 10px;
}

.loader-2:after {
  content: " ";
  display: block;
  width: 50px;
  height: 50px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #17044b transparent #3c0556 transparent;
  animation: loader-2-dual-spin 2s linear infinite;
}

.loader-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

@keyframes loader-2-dual-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.contact-row {
  padding: 30px;
  border: 1px solid var(--main-border);
  border-radius: 10px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  box-shadow: 0px 8px 16px 0px #00000014, 0px 0px 3px 0px #0000000a;
}