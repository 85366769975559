@media screen and (max-width: 1600px) {
      .container {
            max-width: 1140px;
      }

      .header-logo-box .header-logo {
            height: 50px;
            max-width: 50px;
      }

      .header-main {
            padding: 10px 0px;
      }

      .border-btn-main .border-btn {
            padding: 7px 17px;
            font-size: 15px;
            line-height: 22px;
      }

      .header-btn-main .header-btn,
      .header-btn-main .header-btn:hover {
            padding: 8px 29px;
            font-size: 15px;
            line-height: 22px;
      }

      .section-title-main .section-title-para {
            font-size: 15px;
            line-height: 20px;
      }

      .why-choose-section,
      .token-section,
      .roadmap-section,
      .team-section {
            padding: 70px 0px;
            height: auto;
      }

      .faq-section {
            padding: 30px 0px 70px;
      }

      .hero-section {
            padding: 100px 0px;
            height: 650px;
      }

      .hero-coin-img {
            max-width: 400px;
            height: 400px;
      }

      .hero-section::after {
            left: 2%;
            bottom: 5%;
      }

      .hero-content-main .hero-title {
            font-size: 64px;
            line-height: 71px;
      }

      .hero-btn-main {
            margin: 35px 0px 24px;
      }

      .section-title-main .section-title {
            font-size: 43px;
            line-height: 55px;
      }


      .section-title-main .section-backword-title {
            font-size: 80px;
            line-height: 105px;
      }

      .team-content-main .team-title {
            font-size: 18px;
            line-height: 25px;
            min-height: 25px;
      }

      .team-card {
            padding: 20px;
            margin-bottom: 13px;
      }

      .team-content-main .team-sub-title {
            font-size: 15px;
            line-height: 22px;
            min-height: 22px;
      }

      .roadmap-row .roadmap-owl .owl-nav .owl-next {
            right: 0;
      }

      .roadmap-row .roadmap-owl .owl-nav .owl-prev {
            left: 0;
      }

      .accrodion-main {
            margin-top: 40px;
      }

      .choose-round-main {
            height: 70px;
            max-width: 70px;
            min-width: 70px;
      }

      .choose-round-fill {
            height: 45px;
            max-width: 45px;
      }

      .choose-logo {
            height: 30px;
            width: 30px;
      }

      .choose-col .choose-title {
            font-size: 18px;
            line-height: 25px;
      }

      .choose-col .choose-para {
            font-size: 14px;
            line-height: 21px;
      }

      .tokenomics-content-main .tokenomics-content-lable,
      .tokenomics-content-main .tokenomics-content-title {
            font-size: 18px;
            line-height: 22px;
      }

      .tokenomics-content-main .tokenomics-content-digit,
      .tokenomics-content-main .tokenomics-content-para {
            font-size: 16px;
            line-height: 22px;
      }

      .tokenomics-content-box {
            margin-bottom: 15px;
            padding: 17px 22px;
      }

      .token-section::before,
      .token-section::after {
            height: 255px;
            width: 270px;
      }


      .token-chart-value-box .token-chart-value-lable {
            font-size: 20px;
            line-height: 24px;
      }

      .accrodion-main .accrodion-summary {
            font-size: 17px;
            line-height: 25px;
            padding: 15px 20px;
      }

      .accrodion-main .accrodion-details {
            font-size: 14px;
            line-height: 21px;
            padding: 0px 20px 20px;
      }

      .footer-social-main {
            margin-bottom: 40px;
      }

      .footer-content-main .footer-title {
            font-size: 31px;
            line-height: 39px;
            margin-bottom: 40px;
      }

      .footer {
            height: auto;
            padding: 50px 0px 0px;
      }

      .hero-copy-main .hero-copy-text {
            font-size: 16px;
            line-height: 23px;
      }

      .hero-coin-img {
            height: 375px;
            width: 375px;
      }

      .header-nav-main .header-nav-link {
            font-size: 16px;
            line-height: 22px;
      }

      .header-nav-main .header-nav-ul {
            gap: 38px;
      }

      .blog-left-main {
            max-width: 650px;
            flex: 0 0 650px;
      }

      .inner-page-banner-content .inner-page-heading {
            font-size: 30px;
            line-height: 53px;
      }

      .blog-deatils-img {
            height: 250px;
      }

      .blog-deatils-card .blog-deatils-card-heading {
            font-size: 25px;
            line-height: 38px;
            margin-bottom: 7px;
      }

      .blog-deatils-card .blog-deatils-card-para {
            font-size: 14px;
            line-height: 24px;
      }

      .blog-recet-content-main .blog-recent-list-text {
            font-size: 13px;
            line-height: 17px;
      }

      .inner-section-title-main .inner-section-title {
            font-size: 28px;
            line-height: 44px;
      }

      .blog-artical-col .blog-artical-card-heading {
            font-size: 17px;
            line-height: 24px;
            margin-bottom: 7px;
      }

      .blog-artical-conetnt-main {
            padding: 17px;
      }

      .blog-artical-col .blog-artical-card-para {
            font-size: 14px;
            line-height: 23px;
      }

      .gray-card {
            padding: 20px 20px 5px;
      }

      .blog-resent-date-icon {
            height: 15px;
            width: 15px;
      }

      .blog-resent-date-flex .blog-recent-date-text {
            font-size: 13px;
            line-height: 18px;
      }

      .blog-profile-img {
            height: 40px;
            width: 40px;
      }

      .blog-profile-content-main .blog-profile-name {
            font-size: 14px;
            line-height: 18px;
      }

      .blog-profile-content-main .blog-profile-date {
            font-size: 12px;
            line-height: 18px;
      }

      .blog-artical-img {
            height: 215px;
      }

      .inner-page-main {
            padding: 55px 0px 55px;
      }
}

@media screen and (max-width: 1366px) {
      .blog-card-inner-right .blog-card-heading {
            font-size: 16px;
            line-height: 25px;
      }

      .blog-card-inner-right .blog-card-para {
            font-size: 12px;
            line-height: 22px;
      }

      .blog-img {
            height: 240px;
      }

      .blog-category-list-main .blog-category-list-item {
            padding: 10px 0px;
      }

      .blog-category-list-main .blog-category-list-text {
            font-size: 12px;
            line-height: 18px;
      }

      .hero-content-main .hero-para {
            font-size: 14px;
            line-height: 22px;
      }

      .hero-content-main .hero-title,
      .hero-highlight-title {
            font-size: 40px;
            line-height: 60px;
            padding-left: 0;
      }
}

@media screen and (max-width: 1024px) {
      .hero-content-main .hero-para {
            font-size: 14px;
            line-height: 19px;
      }

      .hero-coin-img {
            max-width: 270px;
            height: 270px;
      }

      .hero-content-main .hero-title {
            font-size: 41px;
            line-height: 55px;
      }

      .hero-highlight-title,
      .hero-title-small {
            font-size: 43px;
            line-height: 57px;
            padding-left: 0;
      }

      .hero-content-main .hero-para {
            font-size: 13px;
            line-height: 20px;
      }

      .hero-btn-main .hero-btn,
      .hero-btn-main .hero-btn:hover {
            font-size: 11px;
            line-height: 17px;
      }

      .hero-copy-main .hero-copy-text {
            font-size: 15px;
            line-height: 22px;
      }

      .hero-section,
      .faq-section,
      .why-choose-section,
      .token-section,
      .roadmap-section,
      .team-section {
            padding: 60px 0px;
            height: auto;
      }

      .hero-section {
            height: auto;
      }

      .hero-section::after {
            height: 50px;
            width: 50px;
            left: 1%;
            bottom: 1%;
      }

      .headline-card .headline-title {
            font-size: 13px;
            line-height: 18px;
      }

      .section-title-main .section-title {
            font-size: 33px;
            line-height: 44px;
      }

      .choose-col .choose-title {
            font-size: 15px;
            line-height: 25px;
            padding-bottom: 10px;
      }

      .choose-col .choose-para {
            font-size: 12px;
            line-height: 20px;
            margin-top: 15px;
      }

      .choose-round-fill {
            height: 48px;
            max-width: 48px;
      }

      .choose-round-main {
            height: 70px;
            max-width: 70px;
            min-width: 70px;
      }

      .choose-logo {
            height: 25px;
            width: 25px;
      }

      .footer-content-main .footer-title {
            font-size: 25px;
            line-height: 31px;
            margin-bottom: 30px;
      }

      .footer-content-main .footer-para {
            max-width: 100%;
            margin: 25px auto 25px;
            font-size: 14px;
            line-height: 22px;
      }

      .footer {
            padding: 50px 0px 0px;
            height: auto;
      }

      .footer-social-main {
            margin-bottom: 40px;
      }

      .footer-copy-right-box {
            padding: 15px 0px;
      }

      .footer-copy-right-box .footer-copy-right-text {
            font-size: 13px;
            line-height: 17px;
      }

      .accrodion-main .accrodion-summary {
            font-size: 17px;
            line-height: 28px;
            padding: 0px 20px 0px;
      }

      .accrodion-main .accrodion-details {
            font-size: 13px;
            line-height: 20px;
            padding: 0px 20px 20px;
      }

      .accrodion-main {
            margin-top: 30px;
      }

      .team-card {
            padding: 18px;
      }

      .team-img {
            margin-bottom: 10px;
      }

      .section-title-main .section-backword-title {
            font-size: 60px;
            line-height: 88px;
      }

      .section-title-main .section-title-para {
            font-size: 15px;
            line-height: 21px;
      }

      .stepper-ul .stepper-li:before {
            height: 17px;
            width: 17px;
      }

      .stepper-ul .stepper-li:after {
            top: 10px;
      }

      .owl-next img,
      .owl-prev img {
            width: 30px;
            height: 30px;
      }

      .tokenomics-content-main .tokenomics-content-lable,
      .tokenomics-content-main .tokenomics-content-title {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 10px;
      }

      .tokenomics-content-main .tokenomics-content-digit,
      .tokenomics-content-main .tokenomics-content-para {
            font-size: 14px;
            line-height: 22px;
      }

      .tokenomics-content-box {
            margin-bottom: 15px;
            padding: 15px 20px;
      }

      .token-chart-value-box .token-chart-value-text {
            font-size: 17px;
            line-height: 17px;
      }

      .token-row {
            margin-top: 20px;
      }

      .blog-card-inner-right .blog-card-heading {
            font-size: 16px;
            line-height: 23px;
      }

      .blog-card-inner-right .blog-card-para {
            font-size: 13px;
            line-height: 20px;
      }

      .blog-img {
            height: 250px;
      }
}


@media screen and (max-width: 991px) {
      .container {
            padding: 0px 30px;
      }

      /* start mobile menu responsive */

      .header-main {
            padding: 10px 0px;
      }

      .header-right-main .header-login-link {
            margin-right: 10px;
      }

      .header-right-main .mobile-menu-btn {
            padding: 0px;
            min-width: max-content;
            display: block;
      }

      .header-logo-box .header-logo {
            height: 35px;
      }

      .header-logo-box {
            display: flex;
      }

      .header-search-icon {
            width: 13px;
            height: 13px;
      }

      .header-search-box {
            height: 30px;
            width: 30px;
            margin-right: 10px;
      }

      .mobile-menu-icon {
            display: block;
            height: 30px;
            width: 30px;
            object-fit: contain;
      }

      .body-overflow {
            overflow: hidden;
      }

      .mobile-navbar-show {
            display: block;
      }

      .header-nav-main {
            position: fixed;
            overflow: auto;
            left: -500px;
            transition: 0.5s all;
            height: 100vh;
            max-width: 250px;
            width: 100%;
            top: 0px;
            background: linear-gradient(137.48deg, #FFFFFF -47.56%, #F1EAF8 -24.98%, #F2FFFF 7.47%, #EDEEFF 29.18%, #F1E4EB 53.96%, #EFE7FC 74.57%, #E0E0E0 92.77%, #F2F7FF 131.8%, #FCFCFF 149.12%, #FFFFFF 171.71%);
            padding: 15px;
            z-index: 99;
      }

      .header-nav-main.mobilemenu-active {
            left: 0px;
      }

      .header-nav-main .header-nav-ul {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
      }

      .header-nav-main .header-nav-li {
            margin-bottom: 10px;
            margin-right: 0px;
      }

      .header-right-main {
            gap: 10px;
      }

      .header-nav-main .header-nav-link {
            font-size: 15px;
            line-height: 20px;
      }

      /* end mobile menu responsive */

      .border-btn-main .border-btn {
            padding: 5px 16px;
            font-size: 12px;
            line-height: 18px;
            border-radius: 5px;
      }

      .header-btn-main .header-btn,
      .header-btn-main .header-btn:hover {
            padding: 5px 29px;
            font-size: 14px;
            line-height: 20px;
            border-radius: 5px;
      }

      .blog-flex {
            flex-direction: column;
      }

      .inner-page-main {
            padding: 50px 0px 30px;
      }

      .blog-deatils-img {
            height: 185px;
      }

      .blog-deatils-card .blog-deatils-card-heading {
            font-size: 18px;
            line-height: 30px;
      }

      .blog-deatils-card .blog-deatils-card-para {
            font-size: 13px;
            line-height: 22px;
      }

      .blog-share-main {
            padding: 10px;
            margin-top: 25px;
      }

      .blog-left-main {
            max-width: 100%;
            flex: 0 0 100%;
      }

      .blog-inner-title-main .blog-inner-title {
            font-size: 16px;
            line-height: 22px;
      }

      .blog-artical-section {
            margin: 0px 0px 0px;
      }

      .blog-artical-row {
            grid-template-columns: repeat(2, 1fr);
      }

      .footer-nav-main .footer-nav-list {
            gap: 15px;
      }

      .footer-nav-list .footer-nav-link {
            font-size: 11px;
            line-height: 16px;
      }

      .inner-section-title-main .inner-section-title {
            font-size: 20px;
            line-height: 30px;
      }

      .blog-artical-col .blog-artical-card-heading {
            font-size: 15px;
            line-height: 23px;
      }

      .inner-section-title-main {
            margin-bottom: 20px;
      }

      .blog-artical-col .blog-artical-card-para {
            font-size: 12px;
            line-height: 22px;
      }

      .blog-card-inner-right .blog-card-heading {
            font-size: 17px;
            line-height: 25px;
      }

      .blog-card-inner-right .blog-card-para {
            font-size: 12px;
            line-height: 20px;
            margin-bottom: 7px;
      }

      .blog-img {
            height: 218px;
      }
}

@media screen and (max-device-width: 1023px) and (orientation : landscape) {}

@media screen and (max-device-width: 767px) and (orientation: landscape) {}


@media (max-width: 767px) {
      .container {
            padding: 0px 15px;
      }

      .choose-row {
            grid-template-columns: repeat(1, 1fr);
      }

      .footer-input-box {
            flex-direction: column;
            align-items: center;
      }

      .footer-input-box input {
            padding: 10px 20px;
            font-size: 13px;
            line-height: 18px;
      }

      .footer-input-box .form-group {
            width: 100%;
      }

      .section-title-main .section-title {
            font-size: 23px;
            line-height: 30px;
      }

      .section-title-main .section-title-para {
            font-size: 14px;
            line-height: 22px;
      }

      .faq-section,
      .why-choose-section,
      .token-section,
      .roadmap-section,
      .team-section {
            padding: 30px 0px;
            height: auto;
      }

      .footer-input-box .footer-input {
            min-width: 100%;
            max-width: 100%;
      }

      .footer-content-main .footer-title {
            font-size: 17px;
            line-height: 26px;
            margin-bottom: 20px;
      }

      .footer-content-main .footer-para {
            font-size: 12px;
            line-height: 22px;
            max-width: 100%;
            margin: 25px auto 25px;
      }

      .footer-social-main {
            margin-bottom: 30px;
      }

      .footer {
            height: 100%;
            padding: 30px 0px 0px;
      }

      .footer-copy-right-box .footer-copy-right-text {
            font-size: 12px;
            line-height: 17px;
      }

      .footer-copy-right-box {
            padding: 15px 0px;
      }

      .accrodion-main .accrodion-summary {
            font-size: 14px;
            line-height: 23px;
            padding: 7px 15px;
      }

      .accrodion-main .accrodion-details {
            font-size: 12px;
            line-height: 16px;
            padding: 0px 15px 15px;
      }

      .accrodion-main .accrodion {
            margin-bottom: 10px;
      }

      .accrodion-main {
            margin-top: 10px;
      }

      .team-card {
            padding: 15px;
      }

      .team-content-main .team-title {
            font-size: 15px;
            line-height: 21px;
            min-height: 21px;
            margin-bottom: 8px;
      }

      .team-content-main .team-sub-title {
            font-size: 13px;
            line-height: 20px;
            min-height: 2.px;
      }

      .team-img {
            margin-bottom: 10px;
      }

      .team-row {
            margin-top: 25px;
      }

      .section-title-main .section-backword-title {
            font-size: 38px;
            line-height: 53px;
            margin-bottom: -7px;
      }

      .roadmap-card {
            padding: 13px 20px;
      }

      .roadmap-card .roadmap-title {
            font-size: 15px;
            line-height: 21px;
            min-height: 21px;
      }

      .hero-section {
            padding: 50px 0px 80px;
            height: 100%;
      }

      .hero-content-main .hero-title {
            font-size: 48px;
            line-height: 55px;
            text-align: center;
      }

      .hero-title-small {
            font-size: 40px;
            line-height: 48px;
            text-align: center;
      }

      .hero-highlight-title {
            font-size: 44px;
            line-height: 54px;
            text-align: center;
      }

      .hero-content-main .hero-sub-title {
            font-size: 19px;
            line-height: 27px;
            text-align: center;
      }

      .hero-btn-main .hero-btn,
      .hero-btn-main .hero-btn:hover {
            font-size: 11px;
            line-height: 20px;
            padding: 7px 17px;
      }

      .hero-btn-main {
            margin: 25px auto 24px;
            display: flex;
            justify-content: center;
      }

      .hero-copy-main .hero-copy-text {
            font-size: 13px;
            line-height: 24px;
      }

      .hero-copy-main {
            padding: 5px 10px 5px 15px;
      }

      .hero-coin-img {
            max-width: 185px;
            height: 100%;
      }

      .hero-section::after {
            height: 50px;
            width: 50px;
            left: 6%;
            bottom: 2%;
      }

      .order1 {
            order: 1;
            position: relative;
      }

      .hero-content-main .hero-para {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            margin: auto auto 19px;
      }

      .headline-card .headline-title {
            font-size: 12px;
            line-height: 17px;
      }

      .choose-row {
            margin-top: 40px;
      }

      .choose-round-fill {
            height: 50px;
            max-width: 50px;
      }

      .choose-round-main {
            max-width: 75px;
            min-width: 75px;
            height: 75px;
            margin: auto auto 15px auto;
      }

      .choose-logo {
            height: 30px;
            width: 30px;
      }

      .choose-col .choose-title {
            font-size: 16px;
            line-height: 20px;
      }

      .choose-col .choose-para {
            font-size: 13px;
            line-height: 19px;
      }

      .section-title-main .section-backword-sub-title {
            font-size: 15px;
            line-height: 24px;
      }

      .token-row {
            margin-top: 10px;
      }

      .token-chart-value-box .token-chart-value-text {
            font-size: 12px;
            line-height: 19px;
      }

      .token-chart-value-box .token-chart-value-lable {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 7px;
      }

      .tokenomics-content-main .tokenomics-content-lable,
      .tokenomics-content-main .tokenomics-content-title {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 7px;
      }

      .tokenomics-content-main .tokenomics-content-digit,
      .tokenomics-content-main .tokenomics-content-para {
            font-size: 12px;
            line-height: 19px;
      }

      .tokenomics-content-box {
            margin-bottom: 10px;
            padding: 12px 15px;
      }

      .stepper-ul .stepper-li:before {
            width: 10px;
            height: 10px
      }

      .stepper-wrapper {
            margin: 30px 0px 0px;
      }

      .stepper-ul .stepper-li:after {
            top: 6px;
      }

      .roadmap-row .roadmap-owl .owl-nav .owl-prev {
            left: 43%;
            bottom: -34px;
            top: initial;
      }

      .roadmap-row .roadmap-owl .owl-nav .owl-next {
            right: 43%;
            bottom: -34px;
            top: initial;
      }

      .owl-next img,
      .owl-prev img {
            width: 20px;
            height: 20px;
            object-fit: contain;
      }

      .roadmap-row {
            margin-top: 40px;
      }

      .accrodion-main .MuiAccordionSummary-expandIconWrapper {
            height: 15px;
            width: 15px;
      }

      .blog-artical-row {
            grid-template-columns: repeat(1, 1fr);
      }

      .blog-card {
            flex-direction: column;
            margin-bottom: 15px;
      }

      .blog-img {
            border-radius: 10px 10px 0px 0px;
            min-width: 100%;
            max-width: 100%;
            -webkit-border-radius: 10px 10px 0px 0px;
            -moz-border-radius: 10px 10px 0px 0px;
            -ms-border-radius: 10px 10px 0px 0px;
            -o-border-radius: 10px 10px 0px 0px;
            height: 180px;
      }

      .blog-category-list-main .blog-category-list-item {
            padding: 10px 0px;
      }

      .blog-category-list-main .blog-category-list-text {
            font-size: 13px;
            line-height: 17px;
      }

      .blog-card-inner-right {
            padding: 15px;
            border: 1px solid #E9ECEF;
            border-radius: 0px 0px 15px 15px;
            -webkit-border-radius: 0px 0px 15px 15px;
            -moz-border-radius: 0px 0px 15px 15px;
            -ms-border-radius: 0px 0px 15px 15px;
            -o-border-radius: 0px 0px 15px 15px;
      }

      .blog-card-inner-right .blog-card-heading {
            font-size: 16px;
            line-height: 25px;
      }

      .blog-card-inner-right .blog-card-para {
            font-size: 13px;
            line-height: 21px;
            margin-bottom: 10px;
      }

      .blog-details-bg::after {
            height: 40px;
            width: 40px;
            left: 4%;
            bottom: 2%;
      }

      .blog-details-bg::before {
            height: 40px;
            width: 40px;
            right: 4%;
            top: 2%;
      }

      .inner-page-banner-main {
            padding: 40px 0px;
            height: 185px;
      }

      .inner-page-banner-content .inner-page-heading {
            font-size: 23px;
            line-height: 40px;
      }

      .blog-recent-list-img {
            height: 50px;
            width: 60px;
      }

      .inner-page-breadcrumb {
            padding: 6px 20px;
      }

      .inner-page-banner-content .inner-page-breadcrumb-link {
            font-size: 13px;
            line-height: 23px;
      }

      .contact-row {
            padding: 15px;
      }
}

@media (max-width: 549px) {}

@media (max-width: 374px) {

      .header-btn-main .header-btn,
      .header-btn-main .header-btn:hover {
            padding: 5px 11px;
            font-size: 12px;
            line-height: 18px;
      }
}